<template>
  <div class="page-login content-boxed content-boxed-padding center-text" style="margin-top: -1px; overflow: hidden; border: solid 0px #ffffff;">
	  <video id="tapniVideo" muted loop playsinline width="350" style="margin: 0 auto; max-width: 100%; margin-top: -1px; overflow: hidden;" :autoplay="isNative">
		  <source src="https://cdn.tapni.co/images/tapni-card-tap-video-white.mp4" type="video/mp4">
	  </video>
    <h1 class="bold full-top no-bottom center-text">{{lang[this.appLang].welcome}}</h1>
    <p class="full-bottom half-top center-text color-black font-16">{{lang[this.appLang].welcome_p1}}</p>
	<router-link v-if="false" to="/help" class="button-center button button-90 google-button pointer edit-shadow full-top full-bottom">
	  <span> {{lang[this.appLang].how_it_works}}? </span>
	</router-link>
	<div v-if="false" class="decoration decoration-lines-thin full-top"></div>
	<router-link to="register" exact class="button-center button button-90 google-button bg-tapni-grey pointer">
	  <span> {{lang[this.appLang].create_account}} </span>
	</router-link>

	<div class="page-login-links center-text">
	  <router-link to="/login">{{lang[this.appLang].already_registered}}? <b>{{lang[this.appLang].sign_in}}</b></router-link>
	  <div class="clear"></div>
	</div>
    <div v-if=(!this.isNative) style="display: flex; flex-direction: row; justify-content: space-around; width: 90%; margin: 0 auto; margin-top: 40px;" >
      <a href="https://play.google.com/store/apps/details?id=co.tapni.app" target="_blank"><img src="https://cdn.tapni.co/images/playstore.png" class="app-store-icon pointer" style="width:55px"/></a>
      <a href="https://apps.apple.com/ag/app/tapni/id1552930233" target="_blank"><img src="https://cdn.tapni.co/images/appstore.png" class="app-store-icon pointer" style="width:55px"/></a>
      <a href="https://appgallery.huawei.com/#/app/C103931997" target="_blank"><img src="https://cdn.tapni.co/images/appgallery.png" class="app-store-icon pointer" style="width:55px"/></a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'AuthWelcome',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
  	// Autoplay video - iOS 12 controls fix
	const video = document.getElementById('tapniVideo');
	video.play();

    if (this.isLoggedIn) {
      this.$router.push(this.home)
    } else {
      this.$store.commit('setLoading', false);
	  this.$store.commit('setInitialize', true);
	}
  }
}
</script>

<style scoped>
.app-store-icon {
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 9%);
  border-radius: 12px;
}
</style>
